import { useState, useEffect } from 'react'
import { useDebouncedState } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { useGqlQuery } from '@api/gql/gqlServices'
import {
  GetLineaPlayerLeaderboardDocument,
  type GetLineaPlayerLeaderboardQuery,
  GetUserLeaderboardDocument,
  type GetUserLineaPlayerLeaderboardQuery
} from '@generated/generates'
import type { LineaPlayerLeaderboardUser } from '../types'
import { buildUpLineaPlayerLeaderboardTableData } from './helpers'

const useLineaPlayerLeaderboard = (pageSize: number) => {
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const { isConnected } = useAccount()
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0)
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0)
  const { data, isLoading, status, refetch } = useGqlQuery<GetLineaPlayerLeaderboardQuery>(
    GetLineaPlayerLeaderboardDocument,
    {
      limit: pageSize,
      offset: (currentPage - 1) * pageSize
    }
  )

  const { data: userLineaPlayerLeaderboardData } = useGqlQuery<GetUserLineaPlayerLeaderboardQuery>(
    GetUserLeaderboardDocument,
    {},
    { enabled: isConnected } // Disable caching
  )

  useEffect(() => {
    if (data?.lineaLeaderboardCount) {
      setTotalUsersCount(data.lineaLeaderboardCount)
      setTotalPagesCount(Math.ceil(data.lineaLeaderboardCount / pageSize))
    }
  }, [data, pageSize])

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    isLoading,
    status,
    refetch,
    leaderboard:
      status === 'success'
        ? buildUpLineaPlayerLeaderboardTableData(
            data?.lineaLeaderboard as LineaPlayerLeaderboardUser[],
            userLineaPlayerLeaderboardData?.viewerLineaLeaderboard as LineaPlayerLeaderboardUser
          )
        : [],
    currentPage,
    navigateToPage: handleRetrievePage,
    totalPagesCount,
    totalUsersCount,
    isUserInLeaderboard: !!userLineaPlayerLeaderboardData
  }
}

export default useLineaPlayerLeaderboard
