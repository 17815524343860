import { Spinner, TenantImageEnum, useAssetsContext, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center, Flex, Stack, Tabs, TabsList, Image } from 'summon-ui/mantine'
import { Breadcrumbs } from '@components'
import type { LootDrop } from '@generated/generates'
import LootdropCategoriesList from '../components/LootdropCategoriesList'
import LootdropInfo from '../components/LootdropInfo'
import LootdropRewardsList from '../components/LootdropRewardsList'
import LineaGuildLeaderboard from '../components/leaderboard/lineaGuild/LineaGuildLeaderboard'
import LineaPlayerLeaderboard from '../components/leaderboard/lineaPlayer/LineaPlayerLeaderboard'
import useLootdrop from '../hooks/useLootdrop'

const LootdropDetailsView = () => {
  const t = useTranslation()
  const { isLoading, lootdrop } = useLootdrop()
  const { getTenantImage } = useAssetsContext()
  const NotFoundImage = getTenantImage(TenantImageEnum.NotFound)
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const isMinTablet = useMediaScreen({ type: 'min', breakpoint: 'tablet' })
  const isMaxDesktop = useMediaScreen({ type: 'max', breakpoint: 'desktop' })
  const isDesktopOrBigger = useMediaScreen({ type: 'min', breakpoint: 'desktop' })

  if (isLoading) {
    return <Spinner mt={150} />
  }

  const renderRewards = (lootdrop: LootDrop) =>
    lootdrop.rewards &&
    lootdrop.rewards.length > 0 && (
      <LootdropRewardsList
        participantCount={lootdrop.participantCount}
        userMultiplier={lootdrop.multiplier || 1}
        lootdropTotalMultiplier={lootdrop.totalMultiplier}
        rewards={lootdrop.rewards}
      />
    )

  // TODO: Kind of hardcoded.
  // This is just for linea for now. This shall be changed if we adopt leaderboards for other clients other tahn linea
  const renderLineaTabs = (lootdrop: LootDrop) => {
    return (
      <Tabs defaultValue={t('Rewards')} keepMounted={false} mt='md'>
        <Tabs.List pb='lg'>
          <Tabs.Tab value={t('Rewards')}>{t('Rewards')}</Tabs.Tab>
          <Tabs.Tab value={t('Leaderboard')}>{t('Leaderboard')}</Tabs.Tab>
          <Tabs.Tab value={t('Guilds')}>{t('Guilds')}</Tabs.Tab>
        </Tabs.List>
        <TabsList>
          <Tabs.Panel value={t('Rewards')}>{renderRewards(lootdrop)}</Tabs.Panel>
          <Tabs.Panel value={t('Leaderboard')} w='100%'>
            <LineaPlayerLeaderboard />
          </Tabs.Panel>
          <Tabs.Panel value={t('Guilds')} w='100%'>
            <LineaGuildLeaderboard />
          </Tabs.Panel>
        </TabsList>
      </Tabs>
    )
  }

  const renderContentFromTablet = (lootdrop: LootDrop, withBreadcrumbs: boolean) => (
    <>
      <Stack gap='lg'>
        {withBreadcrumbs && <Breadcrumbs lastText={lootdrop.name} />}
        <LootdropInfo lootdrop={lootdrop} />
        {/* TODO: Hardcoding this feature for linea. This shall be scaled or removed */}
        {lootdrop.urlSlug === 'linea' ? renderLineaTabs(lootdrop) : renderRewards(lootdrop)}
      </Stack>
      {lootdrop?.categories && lootdrop.categories.length > 0 && (
        <>
          <LootdropCategoriesList categories={lootdrop.categories} lootdropSlug={lootdrop.urlSlug} />
        </>
      )}
    </>
  )

  const renderBig = (lootdrop: LootDrop) => (
    <Flex w='100%' gap='md'>
      {renderContentFromTablet(lootdrop, true)}
    </Flex>
  )

  const renderTablet = (lootdrop: LootDrop) => (
    <Stack w='100%' gap='md'>
      <Breadcrumbs lastText={lootdrop.name} />
      {renderContentFromTablet(lootdrop, false)}
    </Stack>
  )

  const renderMobile = (lootdrop: LootDrop) => (
    <>
      <Breadcrumbs lastText={lootdrop.name} />
      <Tabs defaultValue={t('Overview')} keepMounted={false} mt='md'>
        <Tabs.List pb='lg'>
          <Tabs.Tab value={t('Overview')}>{t('Overview')}</Tabs.Tab>
          <Tabs.Tab value={t('Quests')}>{t('Quests')}</Tabs.Tab>
        </Tabs.List>
        <TabsList>
          <Tabs.Panel value={t('Overview')}>
            <Stack gap='lg'>
              <LootdropInfo lootdrop={lootdrop} />
              {lootdrop.rewards && lootdrop.rewards.length > 0 && (
                <LootdropRewardsList
                  participantCount={lootdrop.participantCount}
                  rewards={lootdrop.rewards}
                  lootdropTotalMultiplier={lootdrop.totalMultiplier}
                  userMultiplier={lootdrop.multiplier || 1}
                />
              )}
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value={t('Quests')}>
            <Stack gap='lg'>
              {lootdrop?.categories && lootdrop.categories.length > 0 && (
                <LootdropCategoriesList categories={lootdrop.categories} lootdropSlug={lootdrop.urlSlug} />
              )}
            </Stack>
          </Tabs.Panel>
        </TabsList>
      </Tabs>
    </>
  )

  const isTablet = isMinTablet && isMaxDesktop

  return lootdrop ? (
    <Stack>
      {isDesktopOrBigger && renderBig(lootdrop)}
      {isTablet && renderTablet(lootdrop)}
      {isMobile && renderMobile(lootdrop)}
    </Stack>
  ) : (
    <Center h='100%'>
      <Image src={NotFoundImage} />
    </Center>
  )
}

export default LootdropDetailsView
