import { EnhancedPaper } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Flex, Title } from 'summon-ui/mantine'
import LineaGuildLeaderboardTable from './LineaGuildLeaderboardTable'

export const TITLE = 'Guilds'

const LineaGuildLeaderboard = () => {
  const t = useTranslation()

  return (
    <Box className='h-full overflow-hidden'>
      <EnhancedPaper p='lg'>
        <Flex align='center' justify='space-between' h='100%'>
          <Title data-testid='history-title-label' order={4}>
            {t(TITLE)}
          </Title>
        </Flex>
      </EnhancedPaper>
      <LineaGuildLeaderboardTable />
    </Box>
  )
}

export default LineaGuildLeaderboard
